.fs-14 {
    font-size: 14px;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 18px;
}

.fs-22 {
    font-size: 22px;
}

.fs-30 {
    font-size: 30px;
}

.fs-32 {
    font-size: 32px;
}

.fs-40 {
    font-size: 40px;
}

.fs-48 {
    font-size: 48px;
}

.fs-50 {
    font-size: 50px;
}

@media screen and (max-width: 767.98px) {
    .fs-14 {
        font-size: 12px;
    }

    .fs-16 {
        font-size: 13px;
    }

    .fs-18 {
        font-size: 14px;
    }

    .fs-22 {
        font-size: 18px;
    }

    .fs-30 {
        font-size: 22px;
    }

    .fs-32 {
        font-size: 28px;
    }

    .fs-40 {
        font-size: 36px;
    }

    .fs-48 {
        font-size: 38px;
    }

    .fs-50 {
        font-size: 42px;
    }

    .ls-15 {
        letter-spacing: normal !important;
    }
}

.fw-regular {
    font-weight: 400;
}

.fw-medium {
    font-weight: 500;
}

.text-justify {
    text-align: justify;
}

.ls-15 {
    letter-spacing: 15px;
}

.line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
.btn:active,
.btn.active,
label:focus {
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
}