.search-input-container {
    border: 1px solid #ffffff;
    border-radius: 100px;
}

.search-input {
    background-color: transparent;
    color: white;
}

.search-input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1;
    /* Firefox */
}

.search-input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: white;
}

.rrss-border {
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
}

.service-card {
    height: 100%;
    background-color: #ffffff;
    border-radius: 20px;
}

.portfolio-card {
    box-shadow: 1px -1px 20px rgba(0, 0, 0, 0.15);
    padding-top: 7%;
    padding-bottom: 7%;
    max-width: 1070px;
}

.portfolio-img {
    height: 394px;
}

.testimonial-card {
    height: 100%;
}

.quote-card {
    width: 573px;
}

.quote-text {
    height: 66px;
}

.blog-card {
    height: 350px;
    background: #EBEAED;
    border-radius: 10px;
}

.blog-title {
    text-overflow: ellipsis !important;
    white-space: nowrap;
    overflow: hidden;
}

.excerpt-container {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
}

.contact-container {
    width: 370px;
}

.contact-input-border {
    color: rgba(21, 20, 57, 1);
    border: 2px solid #EBEAED;
    box-sizing: border-box;
    border-radius: 100px;
}

.contact-input {
    color: rgba(21, 20, 57, 1);
    background-color: transparent;
}

.contact-input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(21, 20, 57, 1);
    opacity: 1;
    /* Firefox */
}

.contact-input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(21, 20, 57, 1);
}

.lt-button {
    background-color: #9FC248;
    border: none;
}

.lt-button:hover {
    background-color: #718934;
}

.ju-button {
    background-color: #3888A3;
    border: none;
}

.ju-button:hover {
    background-color: #265666;
}

.disabled-button {
    background-color: #828282;
    border: none;
    cursor: not-allowed;
    pointer-events: none
}

.word-break {
    white-space: nowrap;
}

.input-cv::file-selector-button {
    border-radius: 100px;
    background-color: rgb(235, 234, 237);
    padding: 5px 10px 5px 15px;
    border: none;
    color: rgb(51, 51, 51);
    font-size: 18px;
    font-weight: medium;
    margin-bottom: 16px;
}

.carousel-control-next span,
.carousel-control-prev span {
    border: 0px solid black !important;
}

.input-cv::file-selector-button:hover {
    background-color: rgb(215, 214, 216);
}

.tag-logo {
    max-height: 70px;
}

@media screen and (max-width: 1199.98px) {
    .portfolio-img {
        height: 250px;
    }
}

@media screen and (max-width: 767.98px) {

    .service-card {
        height: auto;
    }

    .blog-card {
        height: auto;
    }

    .word-break {
        white-space: normal;
    }

    .portfolio-card {
        padding-left: 0;
    }
}

@media screen and (max-width: 575.98px) {
    .contact-container {
        width: auto;
    }
}